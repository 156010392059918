import PendingReservationsBadge from "@/components/PendingReservationsCounter/PendingReservationsBadge";
import { I18nLocale } from "@/i18n/types";
import BookingPage from "@/pages/BookingPage/BookingPage";
import FeedbackPage from "@/pages/FeedbackPage/FeedbackPage";
import LoginPage from "@/pages/LoginPage/LoginPage";
import ReservationsPage from "@/pages/ReservationPage/ReservationPage";
import ResetPasswordPage from "@/pages/ResetPasswordPage/ResetPasswordPage";
import SettingsPage from "@/pages/SettingsPage/SettingsPage";
import icons from "@/tools/icons";

type VisualRoute = {
  path: string;
  element: JSX.Element;
  visual: { id: keyof I18nLocale["page"]; icon: JSX.Element };
};
type ProtectedRoute = Omit<VisualRoute, "visual"> &
  Pick<Partial<VisualRoute>, "visual">;

export const publicRoutes = [
  { path: "/login", element: <LoginPage /> },
  { path: "/reset-password/:id", element: <ResetPasswordPage /> },
] as const;

export const protectedRoutes: ProtectedRoute[] = [
  {
    path: "/",
    element: <ReservationsPage />,
    visual: {
      id: "home",
      icon: (
        <PendingReservationsBadge>
          <icons.Home />
        </PendingReservationsBadge>
      ),
    },
  },
  {
    path: "/settings",
    element: <SettingsPage />,
    visual: { id: "settings", icon: <icons.Settings /> },
  },
  {
    path: "/feedback",
    element: <FeedbackPage />,
    visual: { id: "feedback", icon: <icons.Feedback /> },
  },
  {
    path: "/booking/:id",
    element: <BookingPage />,
  },
];

export const visualRoutes = protectedRoutes.filter(
  (route) => !!route.visual
) as VisualRoute[];
