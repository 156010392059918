import { ReservationDetails } from "@/components/ReservationDetails/ReservationDetails";
import useLocalization from "@/hooks/useLocalization";
import useReservation from "@/hooks/useReservation";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export default function BookingPage() {
  const id = useParams().id!;
  const { localization } = useLocalization();

  const {
    fetchReservation,
    fetchReservationResult: { loading, data },
  } = useReservation();

  useEffect(() => {
    fetchReservation({
      variables: {
        reservationId: id,
        lang: localization,
      },
    });
  }, [id, localization, fetchReservation]);

  const reservation = useMemo(() => data?.venueReservation, [data]);

  return (
    <Box sx={{ padding: "1rem" }}>
      {loading ? (
        <CircularProgress />
      ) : reservation ? (
        <ReservationDetails reservation={reservation} />
      ) : (
        <p>error</p>
      )}
    </Box>
  );
}
