import PageTemplate from "@/components/Template/PageTemplate/PageTemplate";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { protectedRoutes, publicRoutes } from "./routes";

const routes = (
  <>
    <Routes>
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <PageTemplate canShowHeader={false} canShowSideBar={false}>
              {route.element}
            </PageTemplate>
          }
        />
      ))}
      <Route path="/" element={<ProtectedRoute />}>
        {protectedRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<PageTemplate>{route.element}</PageTemplate>}
          />
        ))}
      </Route>
    </Routes>
  </>
);

export default function Router() {
  return <BrowserRouter>{routes}</BrowserRouter>;
}
