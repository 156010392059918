import useColorMode from "@/hooks/useColorMode/useColorMode";
import useLocalization from "@/hooks/useLocalization";
import icons from "@/tools/icons";
import Button from "@mui/material/Button";

export default function ColorModeSettings() {
  const { t } = useLocalization();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Button
      fullWidth
      startIcon={
        colorMode === "dark" ? <icons.DarkMode /> : <icons.LightMode />
      }
      variant="contained"
      onClick={toggleColorMode}
    >
      {t("command:toggleWhat", {
        interpolation: {
          what: t("nouns:theme"),
        },
      })}
    </Button>
  );
}
