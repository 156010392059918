import icons from "@/tools/icons";
import Button from "@mui/material/Button";
import { Dispatch, SetStateAction } from "react";

type ToggleInquiryButtonProps = {
  setOpenInquiry: Dispatch<SetStateAction<boolean>>;
};
export default function ToggleInquiryButton({
  setOpenInquiry,
}: ToggleInquiryButtonProps) {
  return (
    <Button
      variant="contained"
      onClick={() => setOpenInquiry((openInquiry) => !openInquiry)}
    >
      {<icons.Email />}
    </Button>
  );
}
