import { Guest } from "../types";

export function sortGuests(
  guests: Record<Guest["priceType"], Guest["quantity"]>,
  isPendingReservationUpdate: boolean
) {
  return Object.entries(guests)
    .map(([priceType, quantity]) => ({
      priceType,
      quantity,
    }))
    .filter(({ quantity }) => isPendingReservationUpdate || quantity.current)
    .map((guest) => guest)
    .sort((a, b) => {
      return !a.quantity.current
        ? 1
        : !b.quantity.current
        ? -1
        : a.priceType === "unconfirmed"
        ? 1
        : b.priceType === "unconfirmed"
        ? -1
        : a.priceType.localeCompare(b.priceType);
    });
}
