import { Box, Card, Typography } from "@mui/material";

type GuestCardProps = {
  name: string;
  currentQuantity: number | undefined;
  originalQuantity: number | undefined;
  isReservationUpdate?: boolean;
};

export default function GuestCard({
  name,
  originalQuantity,
  currentQuantity,
  isReservationUpdate,
}: GuestCardProps) {
  const avatarSize = 46;

  return (
    <Card
      sx={{
        display: "flex",
        padding: 1,
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
        opacity: currentQuantity ? 1 : 0.4,
      }}
    >
      <Typography component="div" variant="h5" sx={{ textWrap: "balance" }}>
        {name}
      </Typography>
      <Box
        sx={{
          bgcolor: "primary.main",
          color: "white",
          height: avatarSize,
          minWidth: avatarSize,
          borderRadius: avatarSize / 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "14px",
          flexShrink: 0,
        }}
      >
        <Typography fontSize={32} sx={{ opacity: 0.4, whiteSpace: "pre" }}>
          {isReservationUpdate &&
            originalQuantity !== currentQuantity &&
            `${originalQuantity ?? 0} → `}
        </Typography>
        <Typography fontSize={32}>{currentQuantity ?? 0}</Typography>
      </Box>
    </Card>
  );
}
