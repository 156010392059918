import { convertLocalToUtc } from "@/tools/date/conversion";
import { Dispatch, SetStateAction } from "react";
import { DateRangePickerProps } from "./DateRangePicker";
import { Day } from "./Day";
import WideStaticDatePicker from "./WideStaticDatePicker";
import { FromOrUntilFocus } from "./types";

type DateRangeCalendarProps = DateRangePickerProps & {
  closeCalendar: () => void;
  focus: FromOrUntilFocus;
  setFocus: Dispatch<SetStateAction<FromOrUntilFocus>>;
  width: number;
};

export function DateRangeCalendar({
  closeCalendar,
  setFrom,
  from,
  until,
  setUntil,
  focus,
  setFocus,
  width,
}: DateRangeCalendarProps) {
  return (
    <WideStaticDatePicker
      showDaysOutsideCurrentMonth={false}
      sx={{ width }}
      onClose={closeCalendar}
      onChange={(value) => {
        if (focus === "from") {
          setFrom(convertLocalToUtc(value));
          if (until && value && until < value) setUntil(null);
          setFocus("until");
        } else if (focus === "until") {
          if (from && value && value < from) setFrom(convertLocalToUtc(value));
          else {
            setUntil(convertLocalToUtc(value));
            setFocus(null);
          }
        }
      }}
      slotProps={{
        actionBar: {
          actions: ["clear", "accept"],
          onClear: () => {
            setFrom(null);
            setUntil(null);
            setFocus(null);
          },
          onAccept: () => {
            setFocus(null);
          },
        },
        day: (props) => ({
          from,
          until,
          ...props,
        }),
      }}
      slots={{
        // @ts-expect-error day is missing props
        day: Day,
        toolbar: undefined,
      }}
    />
  );
}
