import LoadingButton from "@/components/LoadingButton/LoadingButton";
import DefaultTimerButton from "@/components/TimerButton/DefaultTimerButton/DefaultTimerButton";
import { VenueReservation } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import useReservation from "@/hooks/useReservation";
import { unixTimeToComponents } from "@/tools/date/conversion";
import { resetReservationGraceTimeMilliseconds } from "@shared/constants";

type ResetReservationButtonProps = {
  reservation: VenueReservation;
  disabled?: boolean;
};
export default function ResetReservationButton({
  reservation,
  disabled,
}: ResetReservationButtonProps) {
  const { t } = useLocalization();
  const { resetReservation } = useReservation();

  return (
    <DefaultTimerButton
      start={
        reservation.answer
          ? Number(reservation.reservationAnswerReceived)
          : undefined
      }
      disabled={disabled}
      disableTimer={disabled}
      pause={!reservation.answer}
      fullWidth
      variant="contained"
      duration={resetReservationGraceTimeMilliseconds / 2}
      onClick={() => resetReservation(reservation.id)}
      CustomButton={LoadingButton}
      thickness={4}
      timeUpComponent={
        reservation.answer
          ? `${t("reservation:answeredAt", {
              interpolation: {
                answer: t(
                  `reservation:${
                    {
                      ACCEPTED: "accepted",
                      NOT_ACCEPTED: "rejected",
                    }[reservation.answer] as "accepted" | "rejected"
                  }`
                ),
              },
            })} ${t("date:date", {
              interpolation: unixTimeToComponents(
                reservation.reservationAnswerReceived!
              ),
            })}`
          : t("reservation:unanswered")
      }
    >
      {t("command:resetWhat", {
        interpolation: {
          what: t("nouns:reservation"),
        },
      })}
    </DefaultTimerButton>
  );
}
