import icons from "@/tools/icons";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Stack from "@mui/material/Stack";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { SettingIcon } from "./SettingIcon";

export type SettingsMenuItemProps = {
  Icon: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
    muiName: string;
  };
  label: string;
  handleClickAccordion: () => void;
  expanded: boolean;
  preview?: string;
};
export const SettingsMenuItem = ({
  Icon,
  label,
  children,
  handleClickAccordion,
  expanded,
  preview,
}: React.PropsWithChildren<SettingsMenuItemProps>) => (
  <Accordion disableGutters expanded={expanded}>
    <AccordionSummary
      expandIcon={<icons.ExpandMore />}
      onClick={() => handleClickAccordion()}
    >
      <Stack direction="row" alignItems="center" gap="1rem" width="100%">
        <SettingIcon Icon={Icon} />
        <Typography flexGrow={1}>{label}</Typography>
        <Typography variant="body2" sx={{ opacity: "50%" }}>
          {preview}
        </Typography>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);
