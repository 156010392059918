import { VenuePortalPasswordRequest } from "@/graphql/operations";
import { gql } from "@apollo/client";

export type GetPasswordRequestQueryInput = {
  id: string;
};
export type GetPasswordRequestQueryResponse = {
  venuePortalPasswordRequest: VenuePortalPasswordRequest;
};

export const GET_PASSWORD_REQUEST_QUERY = gql`
  query GetPasswordRequestQuery($id: ID!) {
    venuePortalPasswordRequest(id: $id) {
      user {
        email

        settings {
          iso639_1
        }
      }
    }
  }
`;
