import { MailerContext } from "@/providers/MailerProvider/MailerContext";
import { useContext } from "react";

export default function useMailer() {
  const context = useContext(MailerContext);
  if (!context) {
    throw new Error("MailerContext must be used within a MailerProvider");
  }

  return context;
}
