// this file defines providers as a list, rather than a nested tree of providers
// because there were too many nested providers which made it difficult to maintain

import MailerProvider from "@/providers/MailerProvider/MailerProvider";
import ReservationsProvider from "@/providers/ReservationsProvider/ReservationsProvider";
import UserProvider from "@/providers/UserProvider/UserProvider";
import VenueProvider from "@/providers/VenueProvider/VenueProvider";
import { createApolloClient } from "@/setup/apollo/apollo";
import { growthbook } from "@/setup/growthBook";
import { ApolloProvider } from "@apollo/client";
import {
  FeaturesReady,
  GrowthBookProvider,
} from "@growthbook/growthbook-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useMemo } from "react";
import ColorModeProvider from "./ColorModeProvider/ColorModeProvider";
import LocalizationProvider from "./LocalizationProvider/LocalizationProvider";
import NotificationProvider from "./NotificationProvider/NotificationProvider";
import SettingsProvider from "./SettingsProvider/SettingsProvider";
import { createProvider } from "./createProvider";

export type Provider<T> = {
  Component: React.ComponentType<React.PropsWithChildren<T>>;
  props?: Omit<T, "children">;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function composeProviders<TProviders extends Array<Provider<any>>>(
  providers: TProviders
): React.ComponentType<React.PropsWithChildren> {
  const ProviderComponent: React.FunctionComponent<React.PropsWithChildren> = ({
    children,
  }) => {
    const initialJSX = <>{children}</>;

    return providers.reduceRight<JSX.Element>(
      (prevJSX, { Component: CurrentProvider, props = {} }) => {
        return <CurrentProvider {...props}>{prevJSX}</CurrentProvider>;
      },
      initialJSX
    );
  };

  return ProviderComponent;
}

type ComposedProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customProviders?: Provider<any>[];
};
export default function ComposedProvider({
  customProviders,
  children,
}: React.PropsWithChildren<ComposedProviderProps>) {
  const ComposedProvider = useMemo(
    () => composeProviders(customProviders ?? providers),
    [customProviders]
  );
  return <ComposedProvider>{children}</ComposedProvider>;
}

// Please reflect changes to the App and the ComposedProvider in the MockEnvironment
// Tests will that tests will have the same setup
// venue-portal/webapp/src/tools/testing/MockEnvironment.tsx
const providers = [
  createProvider(NotificationProvider),
  createProvider(GrowthBookProvider, { growthbook }),
  createProvider(FeaturesReady),
  createProvider(ApolloProvider, { client: createApolloClient() }),
  createProvider(SettingsProvider),
  createProvider(LocalizationProvider),
  createProvider(UserProvider),
  createProvider(ColorModeProvider),
  createProvider(MuiLocalizationProvider, { dateAdapter: AdapterDateFns }),
  createProvider(MailerProvider),
  createProvider(ReservationsProvider),
  createProvider(VenueProvider),
];
