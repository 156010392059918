import LoadingButton from "@/components/LoadingButton/LoadingButton";
import PasswordTextField from "@/components/PasswordTextField/PasswordTextField";
import useColorMode from "@/hooks/useColorMode/useColorMode";
import useLocalization from "@/hooks/useLocalization";
import useUser from "@/hooks/useUser";
import icons from "@/tools/icons";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LanguageDrawerButton from "../components/LanguageDrawer/LanguageDrawerButton";
import { FormProps } from "../types";
import RequestPasswordResetForm from "./RequestPasswordResetForm";

export default function LoginForm({ setForm }: FormProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { toggleColorMode } = useColorMode();
  const { login } = useUser();

  return (
    <>
      <TextField
        label={t("nouns:email")}
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
      />
      <PasswordTextField
        className="sensitive"
        label={t("nouns:password")}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
      />
      <LoadingButton
        variant="contained"
        disabled={!(email && password)}
        endIcon={<icons.Login />}
        onClick={async () => login(email, password, () => navigate("/"))}
        fullWidth
      >
        {t("command:logIn")}
      </LoadingButton>
      <div style={{ flexGrow: 1 }} />
      <div>
        <Button onClick={() => setForm(() => RequestPasswordResetForm)}>
          {t("settings:password.forgot")}
        </Button>
        <LanguageDrawerButton />
        <IconButton onClick={toggleColorMode} aria-label="change theme">
          <icons.Contrast />
        </IconButton>
      </div>
    </>
  );
}
