import useSettings from "@/hooks/useSettings";
import { ColorMode } from "@/types";
import { ThemeProvider } from "@emotion/react";
import deepPurple from "@mui/material/colors/deepPurple";
import orange from "@mui/material/colors/orange";
import createTheme from "@mui/material/styles/createTheme";
import { useCallback, useMemo } from "react";
import { ColorModeContext } from "./ColorModeContext";

export default function ColorModeProvider({
  children,
}: React.PropsWithChildren) {
  const { settings, setColorMode } = useSettings();

  const colorMode = useMemo(
    () => settings.theme as ColorMode,
    [settings.theme]
  );

  const toggleColorMode = useCallback(() => {
    setColorMode((colorMode) => {
      const nextColorMode = colorMode === "dark" ? "light" : "dark";
      return nextColorMode;
    });
  }, [setColorMode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: deepPurple,
          secondary: orange,
          mode: colorMode,
        },
      }),
    [colorMode]
  );

  return (
    <ColorModeContext.Provider
      value={{
        colorMode,
        toggleColorMode,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}
