import Box from "@mui/material/Box";
import Stack, { StackProps } from "@mui/material/Stack";
import { ReactNode } from "react";

type PrimaryAndSecondaryElementProps = {
  primary: ReactNode;
  secondary: ReactNode;
} & StackProps;

export function PrimaryAndSecondaryElement({
  primary,
  secondary,
  ...stackProps
}: PrimaryAndSecondaryElementProps) {
  return (
    <Stack direction="column" {...stackProps}>
      <Box sx={{ textWrap: "nowrap" }}>{primary}</Box>
      <Box sx={{ opacity: 0.5, fontSize: "0.9rem" }}>{secondary}</Box>
    </Stack>
  );
}
