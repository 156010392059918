import { VenuePortalUserSettings } from "@/graphql/operations";
import { Localization } from "@/i18n/types";
import { ColorMode } from "@/types";
import { Dispatch, SetStateAction, createContext } from "react";

type SettingsContext = {
  setColorMode: Dispatch<SetStateAction<ColorMode>>;
  setLocalization: Dispatch<SetStateAction<Localization>>;
  settings: Partial<VenuePortalUserSettings>;
  toggleReceiveReservationEmails: () => void;
  setUserId: Dispatch<SetStateAction<string | null>>;
};

export const SettingsContext = createContext<SettingsContext | null>(null);
