import { BookingDetail, VenueReservation } from "@/graphql/operations";
import { Guest } from "../types";

export function groupGuests(reservation: VenueReservation) {
  const pendingGuests = getPendingGuests(
    reservation.numGuests,
    (reservation.group?.bookings ?? []).flatMap(
      ({ details }) => details
    ) as BookingDetail[]
  );

  const originalPendingGuests = reservation.originalReservation
    ? getPendingGuests(
        reservation.originalReservation.numGuests,
        reservation.originalReservation.snapshot?.details
      )
    : undefined;

  return {
    ...[
      {
        details: (reservation?.group?.bookings ?? []).flatMap(
          ({ details }) => details
        ) as BookingDetail[],
        currentOrOriginal: "current" as const,
      },
      {
        details: reservation.originalReservation?.snapshot?.details ?? [],
        currentOrOriginal: "original" as const,
      },
    ].reduce((bookingAcc, { details, currentOrOriginal }) => {
      details.forEach(({ productPrice, quantity }) => {
        const key = productPrice!.translation?.name ?? productPrice!.name;
        bookingAcc[key] = bookingAcc[key] ?? {
          current: undefined,
          original: undefined,
        };
        bookingAcc[key]![currentOrOriginal] =
          (bookingAcc[key]![currentOrOriginal] ?? 0) + quantity;
      });
      return bookingAcc;
    }, {} as Record<Guest["priceType"], Guest["quantity"]>),
    ...((pendingGuests || originalPendingGuests) && {
      unconfirmed: {
        current: pendingGuests,
        original: originalPendingGuests,
      },
    }),
  };
}

const getPendingGuests = (
  numGuests: number,
  details?: BookingDetail[] | null
) =>
  Math.max(
    0,
    numGuests -
      (details ?? []).reduce((sum, detail) => sum + detail.quantity, 0)
  );
