import { visualRoutes } from "@/Router/routes";
import useLocalization from "@/hooks/useLocalization";
import useUser from "@/hooks/useUser";
import icons from "@/tools/icons";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LogoWithEnvironment from "../../LogoWithEnvironment";

export default function SideBar() {
  const navigate = useNavigate();
  const { logout } = useUser();
  const { t } = useLocalization();

  const currentPath = useLocation().pathname;

  return (
    <Box sx={{ position: "sticky", top: 0, left: 0 }}>
      <List
        disablePadding
        sx={{
          backgroundColor: "primary.dark",
          height: "100vh",
        }}
      >
        <ListItem sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <LogoWithEnvironment />
        </ListItem>

        {visualRoutes.map(({ path, visual: { id, icon } }) => (
          <ListItem key={id} disablePadding>
            <ListItemButton
              sx={{
                paddingRight: "64px",
              }}
              selected={currentPath === path}
              onClick={() => {
                navigate(path);
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>

              <ListItemText
                sx={{ textWrap: "nowrap" }}
                primary={t(`page:${id}`)}
              />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItem disablePadding sx={{ position: "absolute", bottom: 0 }}>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <icons.Logout />
            </ListItemIcon>
            <ListItemText primary={t("command:logOut")} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
