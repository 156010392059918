import { gql } from "@apollo/client";

export type EditVenueReservationMutationInput = {
  reservationId: string;
  answer: string | null;
};
export type EditVenueReservationMutationResponse = {
  editVenueReservationAnswer: boolean;
};
export const EDIT_VENUE_RESERVATION_ANSWER_MUTATION = gql`
  mutation EditVenueReservationAnswer($reservationId: ID!, $answer: String) {
    editVenueReservationAnswer(reservationId: $reservationId, answer: $answer)
  }
`;
