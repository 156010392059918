import ExpandableAccordion from "@/components/ExpandableAccordion/ExpandableAccordion";
import { ReservationDetails } from "@/components/ReservationDetails/ReservationDetails";
import { VenueReservation } from "@/graphql/operations";
import useDevice from "@/hooks/useDevice";
import { ListItemButton } from "@mui/material";
import Box from "@mui/material/Box";
import { ReservationSummary } from "./ReservationSummary";

export type ReservationRowProps = {
  reservation: VenueReservation;
  isSelected: boolean;
  selectReservation: () => void;
};
export default function ReservationRow({
  reservation,
  isSelected,
  selectReservation,
}: ReservationRowProps) {
  const { isMobile } = useDevice();

  return isMobile ? (
    <ExpandableAccordion
      summary={
        <ReservationSummary reservation={reservation} isSelected={isSelected} />
      }
      details={<ReservationDetails reservation={reservation} />}
      isExpanded={isSelected}
      onClick={selectReservation}
    />
  ) : (
    <Box
      sx={{
        backgroundColor: "background.paper",
        top: isSelected ? 0 : "auto",
        bottom: isSelected ? 0 : "auto",
      }}
    >
      <ListItemButton onClick={selectReservation} selected={isSelected}>
        <ReservationSummary reservation={reservation} isSelected={isSelected} />
      </ListItemButton>
    </Box>
  );
}
