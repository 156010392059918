import { VenueReservation } from "@/graphql/operations";
import icons from "@/tools/icons";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type AdditionalInfoProps = { reservation: VenueReservation };
export default function AdditionalInfo({ reservation }: AdditionalInfoProps) {
  return (
    <Card sx={{ padding: "8px" }}>
      <Stack direction="row" gap={2}>
        <icons.Info fontSize="large" />
        <Typography sx={{ whiteSpace: "pre-line" }}>
          {reservation.additionalInfo}
        </Typography>
      </Stack>
    </Card>
  );
}
