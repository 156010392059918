import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  MutationResult,
} from "@apollo/client";
import { createContext } from "react";
import {
  SendVenuePortalFeedbackEmailInput,
  SendVenuePortalFeedbackEmailResponse,
} from "./mutations/sendFeedbackEmailMutation";
import {
  SendReservationInquiryEmailInput,
  SendReservationInquiryEmailResponse,
} from "./mutations/sendReservationInquiryEmailMutation";

type MailerContext = {
  sendVenuePortalFeedbackEmail: (
    body: string,
    options?: MutationFunctionOptions<
      SendVenuePortalFeedbackEmailResponse,
      SendVenuePortalFeedbackEmailInput,
      DefaultContext,
      ApolloCache<unknown>
    >
  ) => Promise<void>;
  sendVenuePortalFeedbackEmailResult: MutationResult<SendVenuePortalFeedbackEmailResponse>;
  sendReservationInquiryEmail: (
    reservationId: string,
    body: string,
    options?: MutationFunctionOptions<
      SendReservationInquiryEmailResponse,
      SendReservationInquiryEmailInput,
      DefaultContext,
      ApolloCache<unknown>
    >
  ) => Promise<void>;
  sendReservationInquiryEmailResult: MutationResult<SendReservationInquiryEmailResponse>;
};

export const MailerContext = createContext<MailerContext | null>(null);
