import CardStack from "@/components/ReservationDetails/components/CardStack/CardStack";
import { VenueReservation } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import icons from "@/tools/icons";
import { useMemo } from "react";
import GuideCard from "./GuideCard";
import PendingGuideCard from "./PendingGuideCard";

type GuideStackProps = { reservation: VenueReservation };
export default function GuideStack({ reservation }: GuideStackProps) {
  const { t } = useLocalization();

  const numPendingGuides = useMemo(
    () =>
      Math.max(
        0,
        reservation.numGuides -
          (reservation.group?.guideAssignments?.length ?? 0)
      ),
    [reservation.numGuides, reservation.group]
  );

  const guideCards = useMemo(
    () =>
      [
        ...(reservation.group?.guideAssignments ?? []).map(({ guide }) => (
          <GuideCard key={guide?.id} {...guide!} />
        )),
        !!numPendingGuides && (
          <PendingGuideCard numPendingGuides={numPendingGuides} />
        ),
      ].filter(Boolean),
    [reservation.group?.guideAssignments, numPendingGuides]
  ) as React.JSX.Element[];

  return (
    <CardStack
      emptyHeader={t("reservation:unconfirmedWhat", {
        interpolation: {
          what: t("reservation:guide"),
        },
      })}
      i18nCountkey={"reservation:guide"}
      Icon={icons.Flag}
      originalCount={reservation.originalReservation?.numGuides}
      count={reservation.numGuides}
      reservation={reservation}
      cards={guideCards}
    />
  );
}
