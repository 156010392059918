import CardStack from "@/components/ReservationDetails/components/CardStack/CardStack";
import { VenueReservation } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import { isPendingReservationUpdate } from "@/hooks/useReservation";
import icons from "@/tools/icons";
import { useMemo } from "react";
import GuestCard from "./GuestCard";
import { groupGuests } from "./helpers/groupGuests";
import { sortGuests } from "./helpers/sortGuests";

type GuestStackProps = { reservation: VenueReservation };
export default function GuestStack({ reservation }: GuestStackProps) {
  const { t } = useLocalization();

  const sortedGuests = useMemo(
    () =>
      sortGuests(
        groupGuests(reservation),
        isPendingReservationUpdate(reservation)
      ),
    [reservation]
  );

  const guestCards = useMemo(
    () =>
      sortedGuests.map(({ priceType, quantity }, index) => (
        <GuestCard
          key={index}
          name={priceType}
          currentQuantity={quantity.current}
          originalQuantity={quantity.original}
          isReservationUpdate={isPendingReservationUpdate(reservation)}
        />
      )),
    [sortedGuests, reservation]
  ) as React.JSX.Element[];

  return (
    <CardStack
      emptyHeader={t("reservation:unconfirmedWhat", {
        interpolation: {
          what: t("reservation:guest"),
        },
      })}
      i18nCountkey={"reservation:guest"}
      Icon={icons.Person}
      originalCount={reservation.originalReservation?.numGuests}
      count={reservation.numGuests}
      reservation={reservation}
      cards={guestCards}
    />
  );
}
