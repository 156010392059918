import { VenueReservation } from "@/graphql/operations";
import { gql } from "@apollo/client";

export type GetVenueReservationsQueryInput = {
  venuePortalUserId: string;
  reservationOffset: number;
  reservationLimit: number;
  lang: string;
  search?: {
    query?: string;
    from?: Date;
    until?: Date;
  };
};
export type GetVenueReservationsQueryResponse = {
  venuePortalUser: {
    id: string;
    pendingReservationsCount: number;

    reservations: {
      totalRecords: number;
      records: VenueReservation[];
    };
  };
};

export const GET_VENUE_RESERVATIONS_QUERY = gql`
  query GetVenuePortalUserVenueReservations(
    $venuePortalUserId: ID
    $reservationOffset: Int
    $reservationLimit: Int
    $lang: String!
    $search: VenuePortalUserVenueReservationsSearchInput
  ) {
    venuePortalUser(id: $venuePortalUserId) {
      id
      pendingReservationsCount

      reservations(
        pendingFirst: true
        offset: $reservationOffset
        limit: $reservationLimit
        search: $search
      ) {
        totalRecords
        records {
          id
          additionalInfo
          answer
          created
          numGuests
          numGuides
          reservationAnswerReceived
          reservationTime

          group {
            id
            time
            date

            bookings {
              id
              details {
                id
                quantity

                productPrice {
                  id
                  name

                  translation(lang: $lang) {
                    id
                    name
                  }
                }
              }
            }

            guideAssignments {
              role
              guide {
                id
                name
                phoneNumber
                profilePic
              }
            }

            product {
              id
              name
            }
          }

          venue {
            id
            name
          }

          originalReservation {
            id
            answer
            numGuests
            numGuides
            reservationAnswerReceived
            snapshot {
              details {
                id
                quantity

                productPrice {
                  id
                  name

                  translation(lang: $lang) {
                    id
                    name
                  }
                }
              }

              guide {
                id
                name
                phoneNumber
                profilePic
              }
            }
          }
        }
      }
    }
  }
`;
