import PendingReservationsBadge from "@/components/PendingReservationsCounter/PendingReservationsBadge";
import { zIndex } from "@/constants";
import icons from "@/tools/icons";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoWithEnvironment from "../../LogoWithEnvironment";
import DrawerButton from "./components/DrawerButton";
import NavigationDrawer from "./components/NavigationDrawer";
import { default as SearchDrawer } from "./components/SearchDrawer";

type DrawerType = "navigation" | "search";

export default function MobileHeader() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState<null | DrawerType>(null);
  const closeDrawer = useCallback(() => setOpenDrawer(null), []);

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "background.paper",
        boxShadow: 0,
        zIndex: zIndex.high,
      }}
      enableColorOnDark={true}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Stack
          direction="row"
          gap={1}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
          alignItems="center"
        >
          <LogoWithEnvironment />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          {[
            { name: "search", Icon: <icons.Search />, Drawer: SearchDrawer },
            {
              name: "navigation",
              Icon: (
                <PendingReservationsBadge>
                  <icons.Menu />
                </PendingReservationsBadge>
              ),

              Drawer: NavigationDrawer,
            },
          ].map(({ name, Icon, Drawer }) => (
            <DrawerButton
              key={name}
              open={openDrawer === name}
              handleClose={closeDrawer}
              handleOpen={() => setOpenDrawer(name as DrawerType)}
              Icon={Icon}
              Drawer={Drawer}
            />
          ))}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
