import EditPasswordForm from "@/components/EditPasswordForm/EditPasswordForm";
import { validatePassword } from "@/components/EditPasswordForm/validatePassword";
import PublicRouteTemplate from "@/components/PublicRouteTemplate/PublicRouteTemplate";
import useLocalization from "@/hooks/useLocalization";
import useUser from "@/hooks/useUser";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function ResetPasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const { t, setLocalization } = useLocalization();

  const navigate = useNavigate();

  const errors = useMemo(
    () => validatePassword(password, confirmationPassword),
    [password, confirmationPassword]
  );

  const { getRequestPasswordEmail } = useUser();
  const id = useParams().id!;

  const loading = false;
  const [email, setEmail] = useState<string | undefined | null>(undefined);

  useEffect(() => {
    getRequestPasswordEmail({ id }).then((value) => {
      setEmail(value.email ?? null);
      setLocalization(value.iso639_1 ?? "en");
    });
  }, [getRequestPasswordEmail, id, setLocalization]);

  return (
    <PublicRouteTemplate onClick={() => navigate("/login")}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography>
            {email === null ? t("messages:invalidPasswordRequest") : email}
          </Typography>
          {email && (
            <EditPasswordForm
              errors={errors}
              password={password}
              setPassword={setPassword}
              confirmationPassword={confirmationPassword}
              setConfirmationPassword={setConfirmationPassword}
              requestId={id}
              onCompleted={() => navigate("/login")}
            />
          )}
        </>
      )}
    </PublicRouteTemplate>
  );
}
