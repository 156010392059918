import { VenueReservation } from "@/graphql/operations";
import { gql } from "@apollo/client";

export type GetVenueReservationQueryInput = {
  reservationId: string;
  lang: string;
};
export type GetVenueReservationQueryResponse = {
  venueReservation: VenueReservation;
};

export const GET_VENUE_RESERVATION_QUERY = gql`
  query GetVenueReservation($reservationId: ID!, $lang: String!) {
    venueReservation(reservationId: $reservationId) {
      id
      additionalInfo
      answer
      created
      numGuests
      numGuides
      reservationAnswerReceived
      reservationTime

      group {
        id
        time
        date

        bookings {
          id
          details {
            id
            quantity

            productPrice {
              id
              name

              translation(lang: $lang) {
                id
                name
              }
            }
          }
        }

        guideAssignments {
          role
          guide {
            id
            name
            phoneNumber
            profilePic
          }
        }

        product {
          id
          name
        }
      }

      venue {
        id
        name
      }

      originalReservation {
        id
        answer
        numGuests
        numGuides
        reservationAnswerReceived
        snapshot {
          details {
            id
            quantity

            productPrice {
              id
              name

              translation(lang: $lang) {
                id
                name
              }
            }
          }

          guide {
            id
            name
            phoneNumber
            profilePic
          }
        }
      }
    }
  }
`;
