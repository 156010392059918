import { VenueReservation } from "@/graphql/operations";
import {
  isPendingReservationUpdate,
  reservationHasPassed,
} from "@/hooks/useReservation";
import useVenue from "@/hooks/useVenue";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import AdditionalInfo from "./components/AdditionalInfo";
import GuestStack from "./components/CardStack/GuestStack";
import GuideStack from "./components/CardStack/GuideStack";
import EditReservationStatusButtons from "./components/EditReservationStatusButtons/EditReservationStatusButtons";
import PendingUpdateSummary from "./components/PendingUpdateSummary";
import ProductButton from "./components/ProductButton";
import ReservationInquiry from "./components/ReservationInquiry/ReservationInquiry";
import ToggleInquiryButton from "./components/ToggleInquiryButton";

type ReservationDetailsProps = {
  reservation: VenueReservation;
};
export function ReservationDetails({ reservation }: ReservationDetailsProps) {
  const { hasMultipleVenues } = useVenue();

  const [openInquiry, setOpenInquiry] = useState(false);

  if (!reservation) return <></>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {hasMultipleVenues && (
          <Typography textAlign="center">{reservation.venue!.name}</Typography>
        )}
        <ProductButton reservation={reservation} />
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <EditReservationStatusButtons
              reservation={reservation}
              disabled={reservationHasPassed(reservation)}
            />
            <ToggleInquiryButton setOpenInquiry={setOpenInquiry} />
          </Stack>
          <ReservationInquiry
            id={reservation.id}
            isOpen={openInquiry}
            setIsOpen={setOpenInquiry}
          />
        </Grid>
      </Grid>
      {isPendingReservationUpdate(reservation) && (
        <Grid item sm={12} sx={{ width: "100%" }}>
          <PendingUpdateSummary reservation={reservation} />
        </Grid>
      )}
      {reservation.additionalInfo && (
        <Grid item sm={12} sx={{ width: "100%" }}>
          <AdditionalInfo reservation={reservation} />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <GuideStack reservation={reservation} />
      </Grid>
      <Grid item xs={12} md={6}>
        <GuestStack reservation={reservation} />
      </Grid>
    </Grid>
  );
}
