import useReservation from "@/hooks/useReservation";
import useVenue from "@/hooks/useVenue";
import icons from "@/tools/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import SearchMenu from "../../../components/SearchMenu/SearchMenu";

export default function SearchBar() {
  const [expanded, setExpanded] = useState(false);
  const { from, until, venueId, productId, resetSearch, hasSearchParams } =
    useReservation();

  const { venueMap, productMap } = useVenue();

  const venue = venueMap.get(venueId || "");
  const product = productMap.get(productId || "");

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      defaultExpanded={false}
      onChange={(_, x) => setExpanded(x)}
      sx={{
        "& .MuiAccordionSummary-content": {
          marginTop: "8px",
          marginBottom: "8px",
        },
      }}
    >
      <AccordionSummary>
        <Stack direction="row" sx={{ width: "100%" }} gap={2}>
          <Stack
            direction="column"
            justifyContent="center"
            sx={{ flexGrow: 1 }}
            gap={1}
          >
            {venue && <RowWithIcon body={venue.name} Icon={icons.Venue} />}
            {product && <RowWithIcon body={product.name} Icon={icons.Tour} />}
            {from && (
              <RowWithIcon
                body={formatDateRange(from, until)}
                Icon={icons.Calendar}
              />
            )}
          </Stack>
          <Stack direction="row" alignItems="flex-start" gap={1}>
            {hasSearchParams && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  resetSearch();
                }}
              >
                <icons.Close />
              </IconButton>
            )}
            <IconButton>
              <icons.Search />
            </IconButton>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <SearchMenu
          onSearch={async () => {
            setExpanded(false);
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

const formatDateRange = (from: Date, until: Date | null) =>
  `${format(from, "yyyy-MM-dd")}${
    until ? ` - ${format(until, "yyyy-MM-dd")}` : ""
  }`;

type RowWithIconProps = {
  body: string;
  Icon: (typeof icons)[keyof typeof icons];
};
function RowWithIcon({ body, Icon }: RowWithIconProps) {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Icon />
      <Typography>{body}</Typography>
    </Stack>
  );
}
