import { jwtDecode } from "jwt-decode";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../localStorage/localStorage";

type JwtPayload = {
  exp: number;
  id: string;
};

export function clearJwtToken() {
  removeLocalStorage("jwtToken");
}

export function setJwtToken(jwtToken: string) {
  return setLocalStorage("jwtToken", jwtToken);
}

export function getJwtToken() {
  return getLocalStorage("jwtToken");
}

export function getDecodedJwtToken() {
  try {
    return jwtDecode<JwtPayload>(getJwtToken()!);
  } catch {
    return undefined;
  }
}

function getJwtTokenProperty<JwtProperty extends keyof JwtPayload>(
  property: JwtProperty
) {
  return getDecodedJwtToken()?.[property];
}

export function getJwtTokenExpiry() {
  return getJwtTokenProperty("exp") ?? 0;
}

export function getJwtTokenId() {
  return getJwtTokenProperty("id");
}

export function isValidSession() {
  return getJwtTokenExpiry() * 1000 > Date.now();
}

export const fetchUser = () => {
  return {
    id: getJwtTokenId(),
    loggedIn: !!getJwtTokenId(),
  };
};
