import CircularTimerButton, {
  CircularTimerButtonProps,
} from "@/components/TimerButton/CircularTimerButton/CircularTimerButton";
import { VenueReservation } from "@/graphql/operations";
import useReservation, { reservationHasPassed } from "@/hooks/useReservation";
import icons, { Icon } from "@/tools/icons";
import Badge from "@mui/material/Badge";
import { SvgIconOwnProps } from "@mui/material/SvgIcon";
import { resetReservationGraceTimeMilliseconds } from "@shared/constants";
import { useMemo } from "react";

type StatusIconProps = Pick<CircularTimerButtonProps, "sx"> & {
  reservation: VenueReservation;
};
export default function StatusIcon({ reservation, sx }: StatusIconProps) {
  const { resetReservation } = useReservation();
  const disabled = reservationHasPassed(reservation);

  const {
    color,
    Icon,
    onClick,
  }: {
    color: SvgIconOwnProps["color"];
    onClick?: (reservationId: VenueReservation["id"]) => void;
    Icon: Icon;
  } = useMemo(
    () =>
      ({
        ACCEPTED: {
          Icon: icons.Accept,
          color: "success" as SvgIconOwnProps["color"],
          onClick: resetReservation,
        },
        NOT_ACCEPTED: {
          Icon: icons.Cancel,
          color: "error" as SvgIconOwnProps["color"],
          onClick: resetReservation,
        },
        PENDING: {
          Icon: icons.Unknown,
          color: (disabled ? "error" : "secondary") as SvgIconOwnProps["color"],
        },
      }[reservation.answer ?? "PENDING"]!),
    [reservation.answer, disabled, resetReservation]
  );

  const isPendingReservationUpdate = useMemo(
    () =>
      !(
        disabled ||
        reservation.answer ||
        !reservation.originalReservation?.answer
      ),
    [disabled, reservation.answer, reservation.originalReservation?.answer]
  );

  return (
    <CircularTimerButton
      sx={sx}
      color={color}
      disabled={disabled}
      pause={!reservation.answer}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(reservation.id);
      }}
      size={41}
      thickness={6}
      start={
        reservation.answer
          ? Number(reservation.reservationAnswerReceived)
          : undefined
      }
      duration={resetReservationGraceTimeMilliseconds / 2}
      disableTimer={reservationHasPassed(reservation)}
    >
      {
        <Badge badgeContent={isPendingReservationUpdate && <icons.Change />}>
          <Icon fontSize="large" color={color} />
        </Badge>
      }
    </CircularTimerButton>
  );
}
