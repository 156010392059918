import { errors } from "@shared/errors";

// The enLocale is the source of truth for translation fields
// and is used as a fallback.
//
// in other words, adding or removing fields in the enLocale const
// will reflect in the I18nLocale & I18nKey types
//
// Please keep the enLocale in alphabetical order

export const enLocale = {
  identity: { name: "English", flag: "🇬🇧" },

  command: {
    accept: "accept",
    confirmWhat: "confirm {{what}}",
    editWhat: "edit {{what}}",
    logIn: "log in",
    logOut: "log out",
    reject: "reject",
    resetWhat: "reset {{what}}",
    requestWhat: "request {{what}}",
    save: "save",
    search: "search",
    sendWhat: "send {{what}}",
    sendingWhat: "sending {{what}}",
    setWhat: "set {{what}}",
    toggleWhat: "toggle {{what}}",
  },

  date: {
    date: "{{year}}-{{month}}-{{day}}",
    Fri: "Fri",
    Mon: "Mon",
    Sat: "Sat",
    Sun: "Sun",
    Thu: "Thu",
    Tue: "Tue",
    Wed: "Wed",
  },

  error: {
    [errors.invalidCredentials]: "invalid credentials",
    [errors.missingCredentials]: "missing credentials",
    [errors.unauthorized]: "unauthorized",
    [errors.graceTimerExpired]: "reset timer expired",
  },

  messages: {
    feedbackExplanation:
      "We value your input on the Venue Portal! How has your experience been so far? What aspects do you like or find challenging? Please share your feedback with us, so we can continue to enhance the product to better meet your needs.",
    feedbackGratitude: "Thank you for your feedback",
    inquiryExplanation:
      "Have questions about this reservation? Feel free to ask them here, and we will respond as quickly as possible.",
    invalidPasswordRequest:
      "This password request is no longer valid. Please try requesting a new password again.",
  },

  notification: {
    feedback: {
      failedToSendFeedback: "could not send feedback",
      sentFeedback: "sent feedback",
    },
    inquiry: {
      failedToSendInquiry: "could not send inquiry",
      sentInquiry: "sent inquiry",
    },
    login: {
      failedToLogin: "failed to login",
      loggedIn: "logged in",
      loggedOut: "logged out",
    },
    password: {
      failedToSavePassword: "could not save password",
      failedToSendPasswordResetEmail: "could not send password reset email",
      savedPassword: "saved password",
      sentPasswordResetEmail: "sent password reset email",
    },
    reservation: {
      acceptedReservation: "accepted reservation",
      failedToUpdateReservation: "could not update reservation",
      rejectedReservation: "rejected reservation",
      resetReservation: "reset reservation",
    },
  },

  page: {
    feedback: "feedback",
    home: "home",
    settings: "settings",
  },

  passwordValidation: {
    confirmationPasswordMismatch: "matching confirmation password",
    passwordMinimumLength: "at least 8 characters long",
    passwordNeedsLowercase: "at least one lower case letter",
    passwordNeedsSpecialCharacter: "at least one special character",
    passwordNeedsUppercase: "at least one upper case letter",
    passwordShould: "password should be",
  },

  reservation: {
    accepted: "accepted",
    answeredAt: "{{answer}} at",
    endOfReservations: "End of reservations",
    guest_one: "guest",
    guest_other: "guests",
    guide_one: "guide",
    guide_other: "guides",
    noReservations: "No reservations",
    rejected: "rejected",
    unanswered: "unanswered",
    unconfirmedWhat: "No {{what}} confirmed yet",
    updateRequestedAt: "Update requested at",
  },

  settings: {
    mode: { dark: "dark", light: "light" },
    notificationSettings: "notification settings",
    password: {
      current: "current password",
      forgot: "forgot password",
      new: "new password",
    },
    receiveEmailNotifications: "receive email notifications",
  },

  nouns: {
    all: "all",
    contact: "contact",
    email: "email",
    feedback: "feedback",
    from: "from",
    inquiry: "inquiry",
    language: "language",
    password: "password",
    profile: "profile",
    reservation: "reservation",
    siteName: "Venue Portal",
    theme: "theme",
    tour: "tour",
    until: "until",
    venue: "venue",
  },
};
