import { Localization } from "@/i18n/types";
import { VenuePortalError } from "@shared/errors";
import { createContext } from "react";
import { EditPasswordMutationInput } from "./mutations/editPasswordMutation";
import { RequestPasswordInput } from "./mutations/requestPasswordMutation";
import { GetPasswordRequestQueryInput } from "./queries/getRequestPasswordEmailQuery";

type UserContext = {
  user: {
    id: string | undefined;
    loggedIn: boolean;
  };
  login: (
    email: string,
    password: string,
    onCompleted?: () => void
  ) => Promise<{
    error: VenuePortalError | undefined;
  }>;
  logout: () => void;
  editPassword: (
    variables: EditPasswordMutationInput,
    onCompleted?: () => void
  ) => Promise<void>;
  requestPassword: (
    variables: RequestPasswordInput,
    onCompleted?: () => void
  ) => Promise<void>;
  getRequestPasswordEmail: (
    variables: GetPasswordRequestQueryInput
  ) => Promise<{
    email: string | undefined;
    iso639_1: Localization | undefined;
    loading: boolean;
  }>;
};
export const UserContext = createContext<UserContext | null>(null);
