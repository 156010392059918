import { Localization } from "./i18n/types";
import { ColorMode } from "./types";

export const zIndex = {
  high: 9999,
  middle: 1200,
  low: 1,
  base: 0,
} as const;

export const DEFAULT_LOCALIZATION: Localization = "en" as const;
export const DEFAULT_COLOR_MODE: ColorMode = "dark" as const;
