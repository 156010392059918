import { VenueReservation } from "@/graphql/operations";
import icons from "@/tools/icons";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useMemo } from "react";
import { PrimaryAndSecondaryElement } from "./PrimaryAndSecondaryElement";

type GuestsAndGuidesSummaryProps = {
  reservation: VenueReservation;
};
export default function GuestsAndGuidesSummary({
  reservation,
}: GuestsAndGuidesSummaryProps) {
  const mainGuide = useMemo(
    () => reservation.group?.guideAssignments?.[0],
    [reservation.group?.guideAssignments]
  );

  return (
    <PrimaryAndSecondaryElement
      sx={{ flexGrow: 0, width: "120px" }}
      primary={
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{
            float: "right",
          }}
        >
          {reservation.additionalInfo && <icons.Info />}
          <Stack direction="row" alignItems="center">
            {reservation.numGuests}

            <icons.Person />
          </Stack>
          <Stack direction="row" alignItems="center">
            {reservation.numGuides}

            <icons.Flag sx={{ position: "relative", marginLeft: "2px" }} />
          </Stack>
        </Stack>
      }
      secondary={
        <Box
          sx={{
            float: "right",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {mainGuide?.guide?.name}
        </Box>
      }
    />
  );
}
