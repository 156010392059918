import { isProductionEnvironment } from "@/tools/environment/environment";
import { GaPayload, GtagFunction } from "@/types";
import { fetchUser } from "./jwtToken/jwtToken";

export const GA_MEASUREMENT_ID = "G-NNEYNQ9BPM" as const;

export const gaIsSetup = () => !!(typeof window !== "undefined" && window.gtag);

type EventName =
  | "send_feedback"
  | "send_inquiry"
  | "login"
  | "accept_reservation"
  | "reject_reservation"
  | "reset_reservation";

const gtag: GtagFunction = (...args) => {
  if (!isProductionEnvironment())
    return console.info(`Mocking GA4 call for ${JSON.stringify(args)}`);

  if (gaIsSetup()) window.gtag(...(args as Parameters<typeof window.gtag>));
  else
    console.warn("GA4 is not initialized or gtag function is not available.");
};

export const sendGaEvent = (eventName: EventName, payload: GaPayload = {}) =>
  gtag("event", eventName, payload);

export const setGaUserId = (userId: string | null) =>
  gtag("set", "user_id", userId);

export const configGa = () =>
  gtag("config", GA_MEASUREMENT_ID, {
    user_id: fetchUser().id ?? null,
  });
